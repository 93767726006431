const dotenv = require('dotenv');

dotenv.config({ path: './.env.local' });

module.exports = {
  serverRuntimeConfig: {
    ENV: process.env.ENV,
    LOCALE: process.env.LOCALE,
    DOMAIN_NAME: process.env.DOMAIN_NAME,
    CF_SPACE_ID: process.env.CF_SPACE_ID,
    CF_ENVIRONMENT: process.env.CF_ENVIRONMENT,
    CF_DELIVERY_ACCESS_TOKEN: process.env.CF_DELIVERY_ACCESS_TOKEN,
    CONTENTFUL_PREVIEW_ACCESS_TOKEN: process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN,
    ALGOLIA_WRITE_KEY: process.env.ALGOLIA_WRITE_KEY,
    ALGOLIA_APP_ID: process.env.ALGOLIA_APP_ID,
    ALGOLIA_INDEX: process.env.ALGOLIA_INDEX,

  },
 
};
