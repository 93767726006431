import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'next/head';
import get from 'lodash/get';
import { generatePrivacyObject } from '@mw/privacy-link';
import ComponentsMapper from '../src/helpers/components-mapper';
import Layout from '../src/components/Layout/Layout';
import {
    getPageContentTypes,
    getPage,
    getHeader,
    getFooter,
    getPGDataLayer,
    getProductListingFilters,
    getProductHeaders,
    getStickyBanner,
} from '../src/adapters/contentful.adapter';
import buildComponentQuery from '../src/helpers/buildPageQuery';
import { contentType, ENLang, CountCodeUS, privacyLinkCode } from '../src/constants/label.constants';

import AppContext from '../src/context/App.context';
import { generateBreadcrumbs } from '../src/helpers/breadcrumbs';


const PageComponents = ({ data, pageInfo }) => {
    const currentPageUrl = `${process.env.DOMAIN_NAME}${pageInfo.url}`;
    return data.map((props, index) => <ComponentsMapper {...props} currentPageUrl={currentPageUrl} key={index} />);
};

const Page = (props) => {
    const { header, footer, stickyBanner, content, pageInfo, pgDataLayer, breadcrumbs, appData, prodHeaderFirstImage, privacyLink } = props;
    const [isNavigationRendered, setIsNavigationRendered] = useState(false);
    const [isProdListRendered, setIsProdListRendered] = useState(false);
    const [isWriteReviewFormOpen, setIsWriteReviewFormOpen] = useState(false);
    const [isModalOpen, setIsCollectionsOpen] = useState(false);
    const currentPageUrl = `${process.env.DOMAIN_NAME}${pageInfo.url}`;
    const resultURL = pageInfo.url.slice(3);
    const currentPageUrlAR = `${process.env.DOMAIN_NAME}/ar${resultURL}`;
    const ref = createRef();
    const contentWrapperRef = ref;

    const appState = {
        navigation: { isNavigationRendered, setIsNavigationRendered },
        prodListing: { isProdListRendered, setIsProdListRendered },
        writeAndReview: { isWriteReviewFormOpen, setIsWriteReviewFormOpen },
        productListCollection: { isModalOpen, setIsCollectionsOpen },
    };

    return (
        <AppContext.Provider value={{ appData, appState, ref }}>
            <Helmet>
                <link rel="alternate" href={currentPageUrl} hreflang="x-default" />
                <link rel="alternate" hreflang="en" href={currentPageUrl} />
                <link rel="alternate" hreflang="ar" href={currentPageUrlAR} />

            </Helmet>
            <div className="main-container" id="main-container" ref={contentWrapperRef} tabIndex="-1">
                <Layout
                    productHeaderImg={prodHeaderFirstImage}
                    header={header}
                    footer={footer}
                    pageInfo={pageInfo}
                    stickyBanner={stickyBanner}
                    pgDataLayer={pgDataLayer}
                    breadcrumbs={breadcrumbs}
                    privacyLink={privacyLink.link}
                >
                    <PageComponents data={content} pageInfo={pageInfo} />
                </Layout>
            </div>
        </AppContext.Provider>
    );
};

Page.getInitialProps = async ({ query }) => {
    const { pageId } = query;
    const { productHeader, productListing } = contentType;
    const pageContentTypes = await getPageContentTypes(pageId);
    const pgDataLayer = await getPGDataLayer();
    const pageComponentsQuery = await buildComponentQuery(pageContentTypes);

    const contentCollectionLimit = pageContentTypes.find(ct => ct.__typename === productListing) ? 2 : 11;

    const page = await getPage(pageId, pageComponentsQuery, contentCollectionLimit);
    const header = await getHeader();
    const footer = await getFooter();
    const stickyBanner = await getStickyBanner();
    const appData = {};
    let prodHeaderFirstImage;

    if (pageContentTypes.find(ct => ct.__typename === productListing)) {
        appData.allFilters = await getProductListingFilters();
        appData.allProducts = await getProductHeaders();
    }

    const content = page.contentCollection.items || [];

    if (pageContentTypes.find(ct => ct.__typename === productHeader)) {
        prodHeaderFirstImage = get(content[0], 'productVariants.items[0].images.items[0].cfmedia.url', '');

        const productData = content.find(el => el.__typename === productHeader);
        const { bvData, name } = productData;
        const gtin = get(productData, 'productVariants.items[0].gtin', '');
        appData.reviewsData = { bvData, name, featuredImage: page.seoHead.featuredImage, gtin };
    }

    const breadcrumbs = generateBreadcrumbs(page.seoHead, process.env.LOCALE);
    // Privacy Link
    const privacyLink = await generatePrivacyObject({
        lang: ENLang,
        countryCode: CountCodeUS,
        linkType: privacyLinkCode,
    });

    return {
        header,
        footer,
        content,
        pageInfo: page.seoHead,
        pgDataLayer,
        appData,
        breadcrumbs,
        prodHeaderFirstImage,
        privacyLink,
        stickyBanner,
    };
};

Page.propTypes = {
    breadcrumbs: PropTypes.array,
    content: PropTypes.array,
    footer: PropTypes.object,
    stickyBanner: PropTypes.object,
    header: PropTypes.object,
    pageInfo: PropTypes.object,
    pgDataLayer: PropTypes.object,
    appData: PropTypes.object,
    prodHeaderFirstImage: PropTypes.string,
    privacyLink: PropTypes.string,
};

export default Page;
