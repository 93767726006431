import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import ProductCard from '../ProductCard/ProductCard';

const ProductResults = ({ results, visible }) => {
    if (!visible) return null;

    const renderCard = (product, i) => {
        const { title, benefit, image, slug } = product;
        const cfImage = { 'url': image };

        return (
            <div className="product-card-wrapper" key={i}>
                <LazyLoad offset={20}>
                    <ProductCard
                        name={title}
                        productBenefit={benefit}
                        imgSrc={cfImage}
                        imgAlt={title}
                        href={slug}
                        className="product-card"
                    />
                </LazyLoad>
            </div>
        );
    };

    return (
        <div id="search-products" className="search-products products">
            <div className="products-wrapper">
                {results.map(renderCard)}
            </div>
        </div>
    );
};

ProductResults.propTypes = {
    results: PropTypes.array,
    visible: PropTypes.bool,
};

export default ProductResults;
