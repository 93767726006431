import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { InternalLink, Img } from '../UI';

const ProductCard = ({ index, slidesLength, imgSrc, href, productBenefit, name, className }) => (
    <div className={className}>
        <InternalLink className={slidesLength < 4 ? `link-${index} link` : 'link'} link={{ url: href }} ariaLabel={`Learn more about ${name}`}>
            <Img
                className="image"
                contentfulImage={imgSrc}
            />
            <span className="product-benefit">{productBenefit}</span>
            <ReactMarkdown className="product-name" rehypePlugins={[rehypeRaw]}>
                {name.replace(/#/g, '###')}
            </ReactMarkdown>
        </InternalLink>
    </div>
);

ProductCard.propTypes = {
    slidesLength: PropTypes.number,
    index: PropTypes.number,
    className: PropTypes.string,
    href: PropTypes.string,
    imgSrc: PropTypes.object,
    name: PropTypes.string,
    productBenefit: PropTypes.string,

};

export default ProductCard;
