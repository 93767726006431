import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';


const BuyNowButton = ({ sku, selectedVariantIndex, name, variants }) => {
    return (
        <div
            data-action-detail={`${name} - ${variants.items[selectedVariantIndex].size}`}
            className="buy-now-button event_buy_now"
        >
            <Head>
                <meta name="ps-key" content={process.env.PRICE_SPIDER_KEY} />
                <meta name="ps-country" content={process.env.PRICE_SPIDER_COUNTRY} />
                <meta name="ps-language" content={process.env.PRICE_SPIDER_LANGUAGE} />
            </Head>
            <div className="ps-widget" role="button" ps-sku={sku} aria-label={`${name} - ${variants.items[selectedVariantIndex].size} - shop now.`} />
        </div>
    );
};

BuyNowButton.propTypes = {
    sku: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    variants: PropTypes.object,
    selectedVariantIndex: PropTypes.number,
    name: PropTypes.string,
};


export default BuyNowButton;
