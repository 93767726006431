import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import Slider from 'react-slick';
import { AriaLiveRegion } from '../UI';
import BackgroundImage from '../UI/BackgroundImage/BackgroundImage';

import ProductCard from '../ProductCard/ProductCard';
import { importedSettings } from './slickSettings.const';
import { getCdnImage } from '../../adapters/cloudinary.adapter';

const ProductCarousel = (props) => {
    const { title, slidesCollection, leftImg, rightImg, ariaLabel } = props;
    const [currentSlide, setCurrentSlide] = useState(0);
    const [clientRender, setClientRender] = useState(false);
    const slidesLength = slidesCollection.items.length;
    const settings = {
        ...importedSettings,
        afterChange: setCurrentSlide,
    };

    useEffect(() => {
        if (process.browser) {
            setClientRender(true);
        }
    }, []);

    const background = {
        backgroundImage: `url(${getCdnImage(leftImg.cfmedia.url)}), url(${getCdnImage(rightImg.cfmedia.url)})`,
    };

    const slides = slidesCollection.items.filter(item => item.seoHead).map((item, index) => {
        return (
            <div key={index} className="Slide">
                <LazyLoad offset={20}>
                    <ProductCard
                        index={index}
                        slidesLength={slidesLength}
                        imgSrc={item.seoHead.featuredImage.cfmedia}
                        href={item.seoHead && item.seoHead.url}
                        productBenefit={item.productBenefit}
                        name={item.name}
                        showRatingsLabel={true}
                        className="product-card"
                    />
                </LazyLoad>
            </div>
        );
    });

    // "separate initializations for server and client due to a react-slik bug; don't change!"
    const renderSlides = () => (
        <>
            {!clientRender && <Slider {...settings}>{slides}</Slider>}
            {clientRender && <Slider {...settings}>{slides}</Slider>}
        </>
    );

    return (
        <LazyLoad offset={20}>
            <BackgroundImage className="product-carousel" backgroundImage={background} ariaLabel={ariaLabel}>
                <div className="slides-container">
                    <h2>{title}</h2>
                    <div className="slides">
                        {renderSlides()}
                        <AriaLiveRegion current={currentSlide + 1} total={slidesLength} />
                    </div>
                </div>
            </BackgroundImage>
        </LazyLoad>
    );
};

ProductCarousel.propTypes = {
    title: PropTypes.string,
    slidesCollection: PropTypes.object,
    leftImg: PropTypes.object,
    rightImg: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default ProductCarousel;
